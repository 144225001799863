import {
  BrowserRouter as RRD_BrowserRouter,
  Switch as RRD_Switch,
  Route as RRD_Route,
} from "react-router-dom";

// import './W3_Css_Light.css';
import './Silicon_Css.css';
import './App.css';
import './Custom.css';
import Route_Signin from "./Routes/signin";
import Route_Error_404 from "./Routes/error_404";
import Route_Root from "./Routes/root";
import Route_Changelogs from "./Routes/changelogs";
import { Helmet } from "react-helmet";
import Route_Exchanges from "./Routes/exchanges";
import Route_Symbols from "./Routes/symbols";
import Route_Symbol from "./Routes/symbol";
import { useEffect } from "react";
import { sha512 } from "sha.js";

function App() {

  // function changeTheme() {
  //   let theme = localStorage.getItem("theme");
  //   let default_light = window.getComputedStyle(document.body).getPropertyValue("--default-light");
  //   let default_dark = window.getComputedStyle(document.body).getPropertyValue("--default-dark");
  //   console.log(default_light)

  //   if (theme == 'light') {
  //     localStorage.setItem("theme", 'dark');
  //     document.documentElement.style.setProperty('--color1', default_light);  
  //     document.documentElement.style.setProperty('--color2', default_dark);
  //   } else {
  //     localStorage.setItem("theme", 'light');
  //     document.documentElement.style.setProperty('--color1', default_dark);
  //     document.documentElement.style.setProperty('--color2', default_light);
  //   }
  // }

  useEffect(() => {
    // Check Authentication
    let date = new Date().getDate().toString();
    let login_token = new sha512().update(date).digest('hex');
    
    let from_ls = localStorage.getItem(login_token);
    if (from_ls == null)
    {
      if (window.location.pathname != "/signin")
      {
        localStorage.clear();
        window.location.href = "/signin";
      }
    }
    else
    {
      if (window.location.pathname == "/signin")
      {
        window.location.href = "/";
      }
    }
  }, []) // add [] to not become forever loop

  return (
    <div className="App">

      <RRD_BrowserRouter>
        <RRD_Switch>
          <RRD_Route path="/" exact>
            <Route_Root />
          </RRD_Route>

          <RRD_Route path="/changelogs" exact>
            <Route_Changelogs />
          </RRD_Route>

          <RRD_Route path="/exchanges" exact>
            <Route_Exchanges />
          </RRD_Route>

          <RRD_Route path="/symbols/">
            <Route_Symbols />
          </RRD_Route>

          <RRD_Route path="/symbol">
            <Route_Symbol />
          </RRD_Route>

          <RRD_Route path="/signin" exact>
            <Route_Signin />
          </RRD_Route>

          <RRD_Route path="*">
            <Route_Error_404 />
          </RRD_Route>
        </RRD_Switch>
      </RRD_BrowserRouter>

      <Helmet>
        <script src="/scripts/theme.js"></script>
      </Helmet>
      
      {/* <div style={{marginTop: "300px", textAlign: "right" }}>
        <button style={{width: "fit-content", padding: "6px", cursor: "pointer", display: "flex", alignItems: "center"}} onClick={()=>{changeTheme()}}>
          <img src="/images/theme-switch.png" height="15"/> <span style={{marginLeft: "6px"}}>Switch Template</span>
        </button>
      </div> */}
    </div>
  );
}

export default App;
