import { useEffect } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export default function Route_Exchanges()
{

  // useEffect(() => {
  //   console.log("Starting . . .")
  //   var xhr = new XMLHttpRequest();
  //   var url = "https://github.deepscilab.com/api/ctp/exchanges.json";
  //   xhr.open("GET", url, true);
  //   xhr.setRequestHeader("Content-Type", "application/json");
  //   xhr.onreadystatechange = function () {
  //     if (xhr.readyState === 4 && xhr.status === 200) {
  //       var json = JSON.parse(xhr.responseText);
  //       console.log(json)
  //     }
  //   };
  //   xhr.send();
  // })

  return (
    <div>
      <br/>
      <Link to="/">Back to Root</Link>
      <title>Exchanges | Crypto Trading Platform</title>
      <h1>Exchanges</h1>

      {/* <div id="loading-div">
        <p>Loading ...</p>
      </div> */}

      <div id="content-div">
      <table>
        <thead>
          <tr>
            <th></th>
            <th>Name</th>
            <th>Address</th>
          </tr>
        </thead>
        
        <tbody>
          <tr>
            <td>1.</td>
            <td>MEXC</td>
            <td><a href="https://mexc.com" target="_blank">mexc.com</a></td>
          </tr>
        </tbody>
      </table>
      </div>
      
    </div>
  );
}
