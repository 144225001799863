import moment from "moment";
import { useEffect, useState } from "react";

export default function Route_Symbol()
{
  const [exchangeParm, exchangeParmSet] = useState("");
  const [symbolParm, symbolParmSet] = useState("");
  const [headElements, headElementsSet] = useState([]);
  const [bodyElements, bodyElementsSet] = useState([]);
  const [updateDate, updateDateSet] = useState("");
  const [loadingMessage, loadingMessageSet] = useState("Loading ...");
  const [warning, warningSet] = useState("");
  const [colorOfTime, colorOfTimeSet] = useState("white");

  useEffect(() => {
    let browser_url = new URL(window.location.href);
    let params = new URLSearchParams(browser_url.search);
    let exchange = params.get('exchange').toLowerCase()
    let symbol = params.get('symbol').toLowerCase();

    exchangeParmSet(exchange.toUpperCase());
    symbolParmSet(symbol.toUpperCase());

    function load_data_from_api()
    {
      console.log("Connecting to API ...")

      var xhr = new XMLHttpRequest();
      var url = `https://ir2.storage.deepscilab.com/ctp/${exchange}-${symbol}-result.json?_=` + new Date().getTime(); // Adding this to skip cache
      xhr.open("GET", url, true);
      xhr.setRequestHeader("Content-Type", "application/json");
      xhr.setRequestHeader('Cache-Control', 'no-cache');
      xhr.onreadystatechange = function () {
        if (xhr.status == 404)
        {
          loadingMessageSet("CTP-Core does not supporting this symbol yet.")
        }
        if (xhr.readyState === 4 && xhr.status === 200) {
          var json = JSON.parse(xhr.responseText);
          



          var agoInSeconds = moment.duration(moment().diff(json.update_date)).asSeconds();
          var agoText = moment.duration(moment().diff(json.update_date)).humanize() + " ago";
          
          if (agoInSeconds > 2*60)
          {
            colorOfTimeSet("red");
            agoText += ". (OLD)"
            warningSet("Warning: CTP-Core is not scaning this symbol at this moment!");
          }
          else
          {
            colorOfTimeSet("green");
            agoText += "."
            warningSet("");
          }

          updateDateSet(agoText);

          delete json.update_date;




          let counter = 1;
          let headElementsArray = [];
          let bodyElementsArray = []
          let timeFrames = []

          Object.keys(json).map(element => {
            timeFrames.push(element);
            headElementsArray.push(
              <th key={counter}>{element}</th>
            );
            counter += 1;
          });
          headElementsSet(headElementsArray);

          let dataKeys = Object.keys(json[timeFrames[0]]);

          dataKeys.forEach((key) => {
            let data = [];

            counter += 1;
            data.push(<td key={counter}>{key}</td>);

            timeFrames.forEach((tf) => {
              counter += 1;

              data.push(
                <td key={counter}>{json[tf][key]}</td>
              );
            });

            bodyElementsArray.push(
              <tr>{data}</tr>
            );
          });

          bodyElementsSet(bodyElementsArray);

          document.getElementById("loading-div").hidden = true;
          document.getElementById("content-div").hidden = false;
        };
      };
      xhr.send();
    };

    const intervalID = setInterval(() =>  {
      load_data_from_api()
    }, 1000);

    return () => clearInterval(intervalID);
  }, [])

  return (
    <div>
      <br/>
      <a href={`/symbols/${exchangeParm.toLowerCase()}`}>Back to {exchangeParm} Symbols</a>
      <title>{exchangeParm}: {symbolParm} Symbol | Crypto Trading Platform</title>
      
      <h1>{exchangeParm}: {symbolParm}</h1>
      {/* <p>Timeframe:</p>
      <select>
        <option>1 Minute</option>
        <option>5 Minutes</option>
        <option>15 Minutes</option>
        <option selected>4 Hours</option>
        <option>1 Day</option>
      </select> */}

      <div id="loading-div">
        <p>{loadingMessage}</p>
      </div>

      <div id="content-div" hidden>
        <p><small>Updated in</small> <b style={{color: colorOfTime}}>{updateDate}</b></p>
        <p style={{fontWeight: "bold", color: "red"}}>{warning}</p>

        <table>
          <thead>
            <tr>
              <th></th>
              {
                headElements
              }
            </tr>
          </thead>
          
          <tbody>

            {
              bodyElements
            }

          </tbody>
        </table>
      </div>
      
    </div>
  );
}
