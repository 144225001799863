import { Link } from "react-router-dom/cjs/react-router-dom.min";

export default function Route_Root()
{
  return (
    <div>
      <title>Root | Crypto Trading Platform</title>
      <h1>Root</h1>
      
    

      {/* <h2>Messages</h2>
      <p>
        1. Status: <b>Offline</b>
        <br />
        2. <span style={{fontWeight:"normal"}}>This is a view-only version of the platform.</span>
      </p> */}



      {/* <h2>Sitemap</h2> */}
      <p>
        {/* - <span><Link to="/changelogs">Changelogs</Link></span>

        <br /> */}

        {/* - <span><Link to="#">Documents</Link></span>

        <br /> */}

        {/* - <span><Link to="/exchanges">Exchanges</Link></span> */}

        {/* <br /> */}

        - <span><a href="/symbols/mexc">MEXC Symbols</a></span>

        <br />

        - <span onClick={() => {
          localStorage.clear();
          window.location.href = "/signin";
        }}><Link to="#">Sign out</Link></span>
      </p>



      {/* <h2>About</h2> */}
      <p>
        <i>
          Crypto Trading Platform v0.1
          <br />
          by <b>DeepSciLab</b> @ 2024
        </i>
      </p>
    </div>
  );
}
