import { useEffect, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export default function Route_Symbols()
{
  let [exchangeName, exchangeNameSet] = useState("");
  let [symbolsList, symbolsListSet] = useState([]);
  let [searchValue, searchValueSet] = useState("");

  useEffect(() => {
    let exchange = window.location.pathname.replace("/symbols/", "");
    exchangeNameSet(exchange.toLocaleUpperCase());

    var xhr = new XMLHttpRequest();
    var url = `/api/${exchange}_symbols.json`;
    xhr.open("GET", url, true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        var json = JSON.parse(xhr.responseText);
        
        let counter = 1;
        let listOfElements = [];

        json.map(el => {
          listOfElements.push(
            <tr key={counter}>
              <td>{counter}.</td>
              <td><a href={`/symbol?exchange=${exchange}&symbol=${el}`}>{el}</a></td>
              <td>{exchange.toUpperCase()}</td>
            </tr>
          );

          counter += 1;
        });

        symbolsListSet(listOfElements);

        document.getElementById("loading-div").hidden = true;
        document.getElementById("content-div").hidden = false;
      };
    };
    xhr.send();
  }, []) // add [] to not become forever loop



  return (
    <div>
      <br/>
      <a href="/">Back to Root</a>
      <title>{exchangeName} Symbols | Crypto Trading Platform</title>
      <h1>{exchangeName} Symbols</h1>

      <div id="loading-div">
        <p>Loading ...</p>
      </div>

      <div id="content-div" hidden>

        <input value={searchValue} onInput={(e) => {

          let newValue = e.target.value.toUpperCase()
          searchValueSet(newValue);
          let rows = document.getElementsByTagName("tbody")[0].getElementsByTagName("tr");
          
          for (var i = 0; i < rows.length; i++) {
            if (rows[i].innerText.toUpperCase().includes(newValue))
            {
              rows[i].hidden = false;
            }
            else 
            {
              rows[i].hidden = true;
            }
          }

          }} placeholder="Search ..." />

        <table>
          <thead>
            <tr>
              <th></th>
              <th>Symbol</th>
              <th>Exchange</th>
            </tr>
          </thead>
          
          <tbody>

            {
              symbolsList
            }

          </tbody>
        </table>
      </div>
      
    </div>
  );
}
