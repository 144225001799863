import { Link } from "react-router-dom/cjs/react-router-dom.min";

export default function Route_Changelogs()
{
  return (
    <div>
      <br/>
      <Link to="/">Back to Root</Link>
      <br/>
      <h1>Changelogs</h1>
      <span>This page may not contain all the information.</span>
      <h3>2024-04-22</h3>
      <h4>Client</h4>
      <ul>
        <li>Client rewrited in ReactJS.</li>
      </ul>

    </div>
  );
}
