
export default function Route_Error_404()
{
  return (
    <div>
      <title>404 Not Found | Crypto Trading Platform</title>
      <h1>404 Not Found</h1>
      <a href="/">Back to Root</a>
    </div>
  );
}
