import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import {sha512} from "sha.js";

export default function Route_Signin()
{
  const [formMessage, formMessageSet] = useState("");
  const [personalIdInput, personalIdInputSet] = useState("");
  const [accessKeyInput, accessKeyInputSet] = useState("");

  let personal_id = "78d29bec6a72c76aa968ca5ece3cfdfdb4b496ede4c72542c0882d9f01822dec5a872c98fdb23633fc6749ed98ae61bbef454385aa038d052b743918171b43db";
  let access_key = "a044577f25424c600970c928d93b8f1e4ae7ee4a2664684d2832c5f9075f90f7b98d6536ae04e954f027a1d97e9dc4b23206088111745e98fa8d1acf13006685";

  function handle_on_validate()
  {
    let pi = new sha512().update(personalIdInput + '0123456789').digest('hex');
    let ak = new sha512().update(accessKeyInput + '0123456789').digest('hex');

    if (pi != personal_id || ak != access_key)
    {
      formMessageSet("Authentication Failed.")
    }
    else
    {
      let date = new Date().getDate().toString();
      let login_token = new sha512().update(date).digest('hex');
      
      localStorage.setItem(login_token, "Valid")
      window.location.href = "/";
    }
  }

  return (
    <div>
      <title>Sign in | Crypto Trading Platform</title>
      <h1>Authentication</h1>

      <p style={{fontWeight: "bold", color: "red"}}>{formMessage}</p>

      <div id="signin-form-holder" hidden>
        <i>Sign in to continue.</i>
        <br />
        <br />

        <label>Personal ID</label>
        <br />
        <input id="username" name="username" value={personalIdInput} onInput={(e) => {
          personalIdInputSet(e.target.value);
        }} type="text"/>
        <br />
        
        <label>Access Key</label>
        <br />
        <input id="password" name="password" value={accessKeyInput} onInput={(e) => {
          accessKeyInputSet(e.target.value);
        }} type="password"/>

        <br />
        <br />
        
      </div>

      <i id="signin-status-message"><small>Please Wait ...</small></i>

      <div>
        <br />
        <i>
            Perofmance and Security by <b>Cloudflare</b>
        </i>
        <div id="cloudflare-turnstile"></div>
      </div>

      <br />
      <br />
      <input onClick={() => {handle_on_validate()}} id="signin-submit-button" type="submit" value="Validate" hidden/>
      
      <p>
        <i>
          Crypto Trading Platform v0.1
          <br />
          by <b>DeepSciLab</b> @ 2024
        </i>
      </p>

      <Helmet>
        <script src="https://challenges.cloudflare.com/turnstile/v0/api.js?onload=onloadTurnstileCallback" defer></script>
        <script src="/scripts/signin-route-script.js"></script>
      </Helmet>
    </div>
  );
}
